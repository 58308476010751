import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Text } from "../components/Core";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Text>
                  <MDXRenderer>{data.page.body}</MDXRenderer>
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default PrivacyPolicy;

export const query = graphql`
  query {
    page: mdx(frontmatter: { slug: { eq: "privacy-policy" } }) {
      body
    }
  }
`;
